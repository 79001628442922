// $platform-name: 'wungeningaboriginalcorporation';

//
// Environment
//

//
// Config values
//
$platform-name: "wungeningaboriginalcorporation";
$assets-path: "../assets/";

//
// Colours
//
$brand-primary: #f15b29;
$brand-primary-text-contrast-override: #fff;
$brand-secondary: #f5d981;

$donate-colour: #be202d;
$bg: #ece9e9;
$dark: #000;
$grey: $dark;
$red: #be202d;
$yellow: #fbb041;
// Create greyscale

// Utility colours

//
// Layout
//

// Breakpoints

// Spacers
$spacer: 1rem;
$spacer-y: $spacer;
$spacer-x: $spacer;
$site-bleed: 20px;
$container-max-width: 1230px;
//
// Misc
//
$body-colour: $grey;
//
// Transitions
//

//
// Logo
//
$logo: "logo-2023.png";
$logo-width: 251px;
$logo-height: 130px;

//
// Typography
//
$font-open-sans: "Open Sans", sans-serif;
$font-family-base: $font-open-sans;
$headings-font-family: "Work sans", serif;
$headings-colour: $grey;
$font-weight-bold: 900;

// Links

// Icon font

//
// Buttons
//
$border-radius: 3px;
$btn-border-radius: $border-radius;
$btn-padding-x: 1em;
$btn-padding-y: 0.75em;
$btn-font-family: $font-open-sans;
$btn-font-weight: $font-weight-bold;
// Donate button

//
// Social icons
// Documentation: https://fresco-docs.netlify.com/#/header/social-icons/
//
$social-icons-border-radius: 100px;
$social-icons-width: 30px;
$social-icons-height: 30px;
$social-icons-font-size: 1rem;

// $social-icons-background-colour: #989898;
// $social-icons-colour: #fff;

$social-icons-background-colour: "brand";
$social-icons-colour: #fff;
// Social network colours - use these by changing $social-icons-use-brand-colours to true

// Header specific

// Footer specific social icons
$social-icons-footer-background-colour: transparent;
//
// Share this page
//

//
// Form elements
//

// Field groups

//
// Tables
//

//
// Card
//
$card-image-border-radius: 1rem;
$card-heading-colour: #58595b;
$card-hover-image-opacity: 1;
$card-hover-image-scale: 1.1;
$card-hover-details-background-colour: $brand-primary;
$card-hover-heading-colour: white;
$card-border: 0;
$card-hover-summary-colour: white;

// Card text overlay

// Card text over

// Card side by side

// Card hover state

// Event cards

//
// Menu admin
//
$menu-admin-enabled: false;
//
// Header
//
$header-background-colour: white;

// Header content
$header-content-margin-top: 0;
$header-content-padding-top: $spacer;
$header-content-margin-bottom: 0.5rem;

// Tagline

// Main call to action
$cta-btn-gap: 20px;
$cta-btn-colour: white;
// Search
$header-search-margin-right: 5px;

// Search - input
$header-search-input-max-width: 0;
$header-search-input-margin-right: 0;
$header-search-input-padding: 0px 0px;
$header-search-input-border-colour: #fff;
$header-search-input-margin-right: 0px;

// Search - button
$header-search-button-background-colour: $brand-primary;
$header-search-button-icon-colour: #fff;
$header-search-button-border-radius: 100px;
$header-search-button-width: 30px;
$header-search-button-height: 30px;
// Search when in nav

// Social icons

//
// Sticky header
//

//
// Navigation
//
$nav-background-colour: transparent;
$nav-normal-align-items: center;
$nav-top-level-item-padding: 15px 20px;
// Top level items
$nav-top-level-item-colour: $dark;
// Submenus
$nav-submenu-item-colour: $dark;
// Burger button

// Nav normal
$nav-normal-margin-bottom: 0rem;
//
// Specifically when no banner present and you have gone for $header-absolute: true
//

//
// Carousel
//
$carousel-heading-font-size: 3em;
$carousel-max-width: 100%;
$carousel-contents-max-width: $container-max-width;
$carousel-image-overlay: linear-gradient(
  to right,
  rgba(0, 0, 0, 0.9) 0%,
  rgba(0, 0, 0, 0) 50%
);
$carousel-details-position-y: center;
$carousel-details-background-colour: transparent;
$carousel-details-box-shadow: none;
$carousel-details-max-width: 500px;
$carousel-heading-colour: #fff;
$carousel-summary-colour: #fff;

$carousel-controls-position-y: center;
$carousel-controls-position-x: split;
$carousel-controls-margin-x: $site-bleed;
$carousel-controls-icon-colour: #fff;
$carousel-read-more-enabled: false;
// Carousel controls (left-right buttons)

// Carousel buttons (dots or tabs)

// Carousel buttons dots

// Carousel buttons tabs

// Carousel video play button (before breakpoint, when carousel details present)

// Carousel scroll button

// Carousel after breakpoint

// Banner min-height under sm breakpoint - helps to keep some vertical height at narrow viewports, especially for letter-box aspect ratios

//
// Home intro
//
$home-intro-background-colour: white;
$home-intro-font-size: 1rem;
$home-intro-padding-y: $spacer * 1;
$home-intro-padding-y: $spacer * 1;
$home-intro-margin-top: 3rem;
//
// Home features
//
$home-features-padding-top: $spacer * 2;
//
// Impact stats
//
$impact-stats-background-image: "impactdots_bg.png";
$impact-stats-colour: white;
// $impact-stats-background-colour: $dark;
$impact-stats-background-image-opacity: 0.2;
// Heading

// Individual stat

// Figure
$impact-stats-figure-font-family: $font-open-sans;
$impact-stats-figure-font-size: 4.5em;
// Summary
$impact-stats-summary-colour: $grey;
//
// Home feeds
//
$home-feeds-background-colour: $bg;
$home-feeds-padding-top: $spacer * 3;
// Feeds title

// Feed item

// Event feed items
$feed-item-event-start-date-enabled: false;
$feed-item-event-location-enabled: false;
// Twitter feed

//
// Footer
//
$footer-boxes-count: 3;
$footer-prefab: 4;
// Footer admin links

// Newsletter
$newsletter-heading-colour: white;
$newsletter-colour: white;
// Newsletter hero - full width form above rest of footer

//
// Context container
//

// Donors list

//
// Posts
//

//
// Header text (container for breadcrumb and page title)
//

//
// Page title
//

//
// Sidebar
//

// Associated lists

//
// Listing
//

// Listed post settings

//
// Breadcrumb
//

//
// Blockquote
//

//
// Fundraising
//

// Find a fundraiser widget

// Top fundraisers

//
// Donation form
//

// Donation amounts

//
// Quick giving panel
//

//
// Home quick giving panel
//
$home-quick-giving-donation-amount-background-colour: $brand-primary;
$home-quick-giving-donate-btn-background-colour: $donate-colour;
$home-quick-giving-donate-btn-colour: white;
$home-quick-giving-donation-amount-figure-colour: white;
$home-quick-giving-donation-amount-selected-figure-colour: $donate-colour;
// Adjacent homepage quick giving

// Overlay homepage quick giving

//
// Totalisers
//

//
// Comments
//

//
// Blog details
//

//
// FAQs
//

//
// Shop
//

// Departments list

// Product-price

// Product post

//
// Subsite
//

// Subsite logo

// Subsite nav

//
// Cookie consent
//
