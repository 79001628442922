//menu

.menuMain .topLevel {
  border-top: solid 1px #dadada;
}

//search
.header-search #siteSearch {
  border-width: 0;
}

//referral

.mainCallToAction a.cta-button.referral {
  background-color: $yellow;
}

//intro
.homeIntro h3 {
  position: relative;
  &::after {
    content: " ";
    display: block;
    width: 160px;
    height: 35px;

    background-image: url($assets-path+"divide.svg");
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: $carousel-breakpoint + 1px) {
  .carouselSlide {
    &:after {
      content: "";
      display: block;
      width: 100%;
      position: absolute;
      bottom: -3px;
      left: 0;
      height: 100%;
      background-image: url($assets-path+"caroursel_wave.svg");
      background-size: 105% auto;
      background-position: bottom center;
      background-repeat: no-repeat;
      pointer-events: none;
      z-index: 2;
      visibility: visible;
    }
  }
}

//homefeatures background

// .homeFeedsWrapper {
//   position: relative;
//   &:after {
//     content: "";
//     background-image: url($assets-path+"dots_bg.svg");
//     background-repeat: no-repeat;
//     background-position: center right;
//     background-size: 100% 100%;
//     opacity: 0.2;
//   }
// }

//card
.homeFeatures .homeFeature:hover {
  border-radius: 1rem;
  .banner {
    border-radius: 1rem;
  }
}

.homefeaturecategory-homeboximpactstats {
  background: linear-gradient(90deg, #f5d981 0%, #f15b29 100%);
}

.homeFeedsWrapper {
  position: relative;
  &:before {
    content: "";
    display: block;
    width: 100%;
    position: absolute;
    top: -28px;
    left: 0;
    height: 100%;
    background-image: url($assets-path+"feed_wave.svg");
    background-size: 100% auto;
    background-position: top center;
    background-repeat: no-repeat;
    pointer-events: none;
    z-index: 2;
    visibility: visible;
  }
}

//quick givig

.homefeaturecategory-homeboxquickgiving {
  padding-bottom: 6rem;
}

//footer

.footerBox[class*="ewsletter"] {
  position: relative;
  background: rgb(245, 217, 129);
  background: linear-gradient(
    90deg,
    rgba(245, 217, 129, 1) 0%,
    rgba(241, 91, 41, 1) 100%
  );

  &:before {
    pointer-events: none;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url(../assets/impactdots_bg.png);
    z-index: 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    opacity: 0.2;
  }
}

ul.footerLinks {
  text-align: center;

  li {
    display: inline-block;
    padding: 0 5px;
  }
}

.footerBox3 {
  margin-top: 0;
  text-align: center;
  font-size: 14px;
  p {
    padding: 10px 0 0;
  }
}

.pageFooter ul.socialIcons {
  margin: 20px auto 20px;
  display: table;
  li {
    display: inline-block;
  }
}

.appealInteractionsWrapper {
  display: none;
}